import React from "react";
import { Col, Row } from "react-bootstrap";
import { connect } from "react-redux";
import { matchPath, RouteComponentProps } from "react-router-dom";
import { loadUserAccounts } from "../../actions/Account";
import { loadCustomerBillDetails } from "../../actions/BillDetails";
import { logout, updateUser, userVerified } from "../../actions/User";
import { login, getCustomerProfile } from "../../api/User";
import {
    IApplication,
    IApplicationRoute,
    IApplicationState,
    IInternationalization,
    ISettings,
} from "../../types";
import { IUser } from "../../types/IUser";
import LoginForm from "../Layout/Header/LoginForm";

interface LoginRouteProps extends IApplicationRoute {
    guid: string;
}

interface LoginStateProps {
    intl: IInternationalization;
    application: IApplication;
    settings: ISettings;
    user: IUser;
}

interface LoginDispatchProps {
    loadUserAccounts: typeof loadUserAccounts;
    loadCustomerBillDetails: typeof loadCustomerBillDetails;
    logout: VoidFunction;
    updateUser: typeof updateUser;
    userVerified: typeof userVerified;
}

export type LoginProps = LoginStateProps &
    LoginDispatchProps &
    RouteComponentProps<IApplicationRoute>;

const getRouteProps = (path: string): LoginRouteProps => {
    const pathToMatch = "/:merchantName?/:page?/:guid?";

    const match = matchPath<LoginRouteProps>(path, {
        path: pathToMatch,
        exact: false,
        strict: false,
    });

    return match ? match.params : ({} as LoginRouteProps);
};

export class Login extends React.Component<LoginProps> {
    constructor(props: any) {
        super(props);
    }

    private onLogin = async (
        userName: string,
        password: string,
        recaptcha?: string | null
    ): Promise<IUser> => {
        return login(
            this.props.application,
            this.props.settings.merchantName,
            userName,
            password,
            recaptcha
        );
    }

    private onContinue = () => {
        const { settings, history } = this.props;
        const params = getRouteProps(this.props.location.pathname);
        let billsAction =
            settings.allowBlindPayments || params.guid ? "bills" : "search";

        if (params.guid) {
            billsAction += `/${params.guid}`;
        }

        history.push(`/${settings.merchantName}/${billsAction}`);
    };

    public render() {
        const { application, settings } = this.props;
        return (
            <Row>
                <Col xs={0} sm={1} md={2} />
                <Col xs={12} sm={10} md={8}>
                    <LoginForm
                        application={application}
                        merchantName={settings.merchantName}
                        allowAutoPay={settings.allowAutoBillPay}
                        requireRecaptcha={settings.requireReCaptcha}
                        requireLoginEBPP={
                            application.isEBPP && settings.requireLoginEBPP
                        }
                        requirePasswordReset={
                            this.props.user.requirePasswordReset
                        }
                        locale={this.props.intl.locale}
                        allowGuestLogin={false}
                        //onClose={() => {}}
                        onContinue={this.onContinue}
                        updateUser={this.props.updateUser}
                        userVerified={this.props.userVerified}
                        login={this.onLogin}
                        logout={this.props.logout}
                        loadUserAccounts={this.props.loadUserAccounts}
                        loadCustomerBillDetails={
                            this.props.loadCustomerBillDetails
                        }
                        webPaymentsCustomerAccountLoginMessage={
                            settings.webPaymentsCustomerAccountLoginMessage
                        }
                        user={this.props.user}
                    />
                </Col>
                <Col xs={0} sm={1} md={2} />
            </Row>
        );
    }
}

export default connect(
    (state: IApplicationState) => ({
        intl: state.intl,
        application: state.application,
        settings: state.settings,
        user: state.user,
    }),
    {
        logout,
        updateUser,
        userVerified,
        loadUserAccounts,
        loadCustomerBillDetails,
    }
)(Login);
