import React from "react";
import { connect } from "react-redux";
import { Alert, Nav, Navbar, NavDropdown } from "react-bootstrap";
import LanguageSelector from "../LanguageSelector/LanguageSelector";
import { LoginModal } from "./LoginModal";
import ChangePasswordModal from "./ChangePasswordModal";
import MerchantLogo from "./MerchantLogo";
import MerchantBanner from "./MerchantBanner";
import { updateUser, logout, userVerified } from "../../../actions/User";
import { loadUserAccounts } from "../../../actions/Account";
import { loadCustomerBillDetails } from "../../../actions/BillDetails";
import { login, updateUserProfile } from "../../../api/User";
import MerchantLinks from "./MerchantLinks";
import MerchantLogin from "./MerchantLogin";
import {
    IApplicationState,
    IApplication,
    IInternationalization,
    ISettings,
    IMyAccount,
} from "../../../types";
import { IUser, IProfile } from "../../../types";
import { History } from "history";
import ManageFactorsModal from "./ManageFactorsModal";

export interface HeaderOwnProps {
    history: History;
    hideLoginButton: boolean;
    hideNavbar: boolean;
}

export interface HeaderStateProps {
    application: IApplication;
    intl: IInternationalization;
    myAccount: IMyAccount;
    settings: ISettings;
    user: IUser;
}

export interface HeaderDispatchProps {
    updateUser?: (user: IUser) => void;
    loadUserAccounts?: (
        application: IApplication,
        merchantName: string,
        authToken: string,
        onError?: (text: string) => void
    ) => void;
    loadCustomerBillDetails?: (
        merchantName: string,
        authToken: string,
        merchantCustomerId: string
    ) => void;
    logout?: VoidFunction;
    userVerified: (value: boolean) => void;
}

export interface HeaderState {
    showLoginModal: boolean;
    showChangePasswordModal: boolean;
    showManageFactorsModal: boolean;
    requirePasswordReset: boolean;
}

export type HeaderProps = HeaderOwnProps &
    HeaderStateProps &
    HeaderDispatchProps;

export class Header extends React.Component<HeaderProps, HeaderState> {
    constructor(props: HeaderProps) {
        super(props);

        this.state = {
            showLoginModal: false,
            showChangePasswordModal: false,
            showManageFactorsModal: false,
            requirePasswordReset: false,
        };

        this.handleOpenModal = this.handleOpenModal.bind(this);
        this.handleCloseModal = this.handleCloseModal.bind(this);
        this.handleOpenPasswordModal = this.handleOpenPasswordModal.bind(this);
        this.handleClosePasswordModal =
            this.handleClosePasswordModal.bind(this);
        this.handleLogout = this.handleLogout.bind(this);
        this.onLogin = this.onLogin.bind(this);
        this.onLoadUserAccounts = this.onLoadUserAccounts.bind(this);
        this.onLoadCustomerBillDetails =
            this.onLoadCustomerBillDetails.bind(this);
        this.onUpdateUserProfile = this.onUpdateUserProfile.bind(this);
        this.handleToggleManageFactorsModal =
            this.handleToggleManageFactorsModal.bind(this);
    }

    public componentDidUpdate(prevProps: any): void {
        const { application, settings } = this.props;
        if (
            this.props.user.requirePasswordReset !==
            this.state.requirePasswordReset
        ) {
            if (application.isEBPP && settings.enableMyAccount) {
                this.setState({
                    requirePasswordReset: this.props.user.requirePasswordReset,
                });
            }
        }
    }

    private handleOpenModal(event: any): void {
        if (event) {
            event.preventDefault();
        }
        this.setState({ showLoginModal: true });
    }

    private handleCloseModal(): void {
        this.setState({ showLoginModal: false });
    }

    private handleClosePasswordModal(): void {
        this.setState({ showChangePasswordModal: false });
    }

    private handleOpenPasswordModal(): void {
        this.setState({ showChangePasswordModal: true });
    }

    private handleToggleManageFactorsModal(): void {
        this.setState({
            showManageFactorsModal: !this.state.showManageFactorsModal,
        });
    }

    private stopPropagation = (event: React.MouseEvent<NavDropdown>): void => {
        event.preventDefault();
        event.stopPropagation();
    };

    private handleLogout(): void {
        const rootPath = `/${this.props.settings.merchantName}/`;
        const myAccountPath = `/${this.props.settings.merchantName}/myaccount/`;

        if (window.location.href.includes(myAccountPath)) {
            this.props.history.push(rootPath);
        }

        if (this.props.logout) this.props.logout();
    }

    private onLogin(
        userName: string,
        password: string,
        recaptcha?: string | null
    ): Promise<IUser> {
        return login(
            this.props.application,
            this.props.settings.merchantName,
            userName,
            password,
            recaptcha
        );
    }

    private onLoadUserAccounts(
        application: IApplication,
        merchantName: string,
        authToken: string,
        onError?: (text: string) => void
    ): void {
        this.props.loadUserAccounts!(
            application,
            merchantName,
            authToken,
            onError
        );
    }

    private onLoadCustomerBillDetails(
        merchantName: string,
        authToken: string,
        merchantCustomerId: string
    ) {
        this.props.loadCustomerBillDetails!(
            merchantName,
            authToken,
            merchantCustomerId
        );
    }

    private onUpdateUserProfile(
        merchantCustomerId: string,
        authToken: string,
        profile: IProfile,
        password: string,
        currentPassword: string
    ): Promise<IUser> {
        return updateUserProfile(
            this.props.application,
            this.props.settings.merchantName,
            authToken,
            profile,
            password,
            currentPassword
        );
    }

    public render(): JSX.Element {
        const { application, settings, user, hideLoginButton, hideNavbar } =
            this.props;
        const { showChangePasswordModal } = this.state;
        const isSecurePayLoggedIn = application.id === 34 && user.isLoggedIn;

        return (
            <div>
                <MerchantBanner bannerImage={settings.bannerImage} />
                {!hideNavbar && (
                    <Navbar collapseOnSelect fluid>
                        <Navbar.Header>
                            <MerchantLogo
                                friendlyName={settings.friendlyName}
                                desktopLogo={settings.desktopLogo}
                                desktopLogoOnly={settings.desktopLogoOnly}
                                mobileLogo={settings.mobileLogo}
                                mobileLogoOnly={settings.mobileLogoOnly}
                                linkUrl={settings.logoUrl}
                                desktopCustomName={settings.desktopCustomName}
                                mobileCustomName={settings.mobileCustomName}
                            />
                            <Navbar.Toggle />
                        </Navbar.Header>
                        <Navbar.Collapse>
                            <MerchantLinks links={settings.links} />
                            <Nav pullRight>
                                {settings.enableLanguageSupport && (
                                    <LanguageSelector
                                        stopPropagation={this.stopPropagation}
                                    />
                                )}
                                {!hideLoginButton &&
                                    settings.enableMyAccount &&
                                    (application.id === 33 ||
                                        isSecurePayLoggedIn) && (
                                        <MerchantLogin
                                            isAutoPayEnabled={
                                                this.props.settings
                                                    .allowAutoBillPay
                                            }
                                            isLoggedIn={
                                                user.isLoggedIn &&
                                                user.isVerified
                                            }
                                            myAccount={this.props.myAccount}
                                            merchantName={settings.merchantName}
                                            openChangePassword={
                                                this.handleOpenPasswordModal
                                            }
                                            openManageFactors={
                                                this
                                                    .handleToggleManageFactorsModal
                                            }
                                            openLogin={this.handleOpenModal}
                                            logout={this.handleLogout}
                                        />
                                    )}
                            </Nav>
                        </Navbar.Collapse>
                    </Navbar>
                )}
                <LoginModal
                    application={this.props.application}
                    merchantName={this.props.settings.merchantName}
                    allowAutoPay={this.props.settings.allowAutoBillPay}
                    requireRecaptcha={this.props.settings.requireReCaptcha}
                    requireLoginEBPP={
                        application.isEBPP && settings.requireLoginEBPP
                    }
                    requirePasswordReset={this.state.requirePasswordReset}
                    locale={this.props.intl.locale}
                    show={this.state.showLoginModal}
                    allowGuestLogin={false}
                    user={user}
                    onClose={this.handleCloseModal}
                    updateUser={this.props.updateUser}
                    userVerified={this.props.userVerified}
                    login={this.onLogin}
                    logout={this.handleLogout}
                    loadUserAccounts={this.onLoadUserAccounts}
                    loadCustomerBillDetails={this.onLoadCustomerBillDetails}
                    webPaymentsCustomerAccountLoginMessage={
                        this.props.settings
                            .webPaymentsCustomerAccountLoginMessage
                    }
                />
                <ChangePasswordModal
                    locale={this.props.intl.locale}
                    localeTranslations={this.props.intl.messages}
                    merchantCustomerId={this.props.user.merchantCustomerID}
                    authToken={this.props.user.authToken}
                    profile={this.props.user.profile}
                    show={showChangePasswordModal}
                    onClose={this.handleClosePasswordModal}
                    updateUserProfile={this.onUpdateUserProfile}
                />
                <ManageFactorsModal
                    application={this.props.application}
                    user={this.props.user}
                    merchantName={this.props.settings.merchantName}
                    updateUser={this.props.updateUser}
                    locale={this.props.intl.locale}
                    localeTranslations={this.props.intl.messages}
                    show={this.state.showManageFactorsModal}
                    onClose={this.handleToggleManageFactorsModal}
                />
            </div>
        );
    }
}

export const mapStateToProps = (state: IApplicationState) => ({
    intl: state.intl,
    application: state.application,
    myAccount: state.myAccount,
    settings: state.settings,
    user: state.user,
});

export const mapDispatchToProps = {
    updateUser,
    loadUserAccounts,
    loadCustomerBillDetails,
    logout,
    userVerified,
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
