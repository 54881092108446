import React from "react";
import * as Resources from "../../resources";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faQuestionCircle,
    faExclamationTriangle,
} from "@fortawesome/free-solid-svg-icons";
import {
    InputGroup,
    Popover,
    OverlayTrigger,
    Panel,
    Row,
    Col,
    Image,
    Alert,
    Checkbox,
} from "react-bootstrap";
import {
    TextInput,
    NumberInput,
    CreditCardNumberInput,
    MonthSelectInput,
    YearSelectInput,
} from "../Input";
import { checkLuhn } from "../../utils/Validation";
import { IsAmericanExpress } from "../../utils/CardTypes";
import FeeTable from "./FeeTable";
import FutureDatePayment from "./FutureDatePayment";
import { CheckFieldValidity, isCardExpired } from "../../utils/Validation";
import GooglePayButton from "@google-pay/button-react";
import cvv3 from "../../images/cvv3.svg";
import cvv4 from "../../images/cvv4.svg";
import { IPayment } from "../../types/IPayment";
import ApplePayButton from 'apple-pay-button';
import { applePayValidateMerchant } from "../../api/Payment";

export enum CreditCardPanelPropsEnum {
    intl,
    application,
    merchant,
    isLoggedIn,
    payment,
    errors,
    onBlur,
    onChange,
    onError,
    clearField,
    hideFeePanel,
    useGooglePay,
    showGooglePay,
    useApplePay,
    showApplePay,
    applePayRequiredFields,
    getApplePayBillingContact,
    allowedCardNetworks, 
    hideCvcPanel,
    readOnlyField,
    showRememberAccount,
    showAccountNickName,
    calculateFee,
    onChecked,
    loadAutoPayTerms,
    maxFuturePaymentDays,
    locale,
}

class CreditCardPanel extends React.Component<any, any> {
    private monthInput: any;

    public static defaultProps: Partial<any> = {
        hideFeePanel: false,
        isLoggedIn: false,
    };

    constructor(props: any) {
        super(props);
        this.state = {
            inputType: "password",
        };
        this.handleBlur = this.handleBlur.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }
    handlePayment = async (paymentData: google.payments.api.PaymentData): Promise<google.payments.api.PaymentAuthorizationResult> => {
        try {


            const { payment } = this.props;
            payment.firstName = paymentData.paymentMethodData.info?.billingAddress?.name;
            payment.address = (paymentData.paymentMethodData.info?.billingAddress?.address1 ?? "") +
                (paymentData.paymentMethodData.info?.billingAddress?.address2 ? " " + paymentData.paymentMethodData.info.billingAddress.address2 : "") +
                (paymentData.paymentMethodData.info?.billingAddress?.address3 ? " " + paymentData.paymentMethodData.info.billingAddress.address3 : "");
            payment.cardNumber = "xxxx xxxx xxxx " + paymentData.paymentMethodData.info?.cardDetails; //This is not a problem. It does not encrypt and include this in request, just gpay token
            payment.city = paymentData.paymentMethodData.info?.billingAddress?.locality;
            payment.country = paymentData.paymentMethodData.info?.billingAddress?.countryCode;
            payment.mobilePhone = paymentData.paymentMethodData.info?.billingAddress?.phoneNumber ?? "";
            payment.phone = paymentData.paymentMethodData.info?.billingAddress?.phoneNumber ?? "";
            payment.postal = paymentData.paymentMethodData.info?.billingAddress?.postalCode;
            payment.state = paymentData.paymentMethodData.info?.billingAddress?.administrativeArea;
            payment.googlePayToken = paymentData.paymentMethodData.tokenizationData.token;
            payment.type = paymentData.paymentMethodData.info?.cardNetwork;
            
            return { transactionState: "SUCCESS" };
        }
        catch (ex){
            console.log("error assigning Payment Prop with google data");
            return { transactionState: "ERROR" };
        }
    };
    handleFocus = (event: any) => {
        if (event.target.name === "cvc") {
            this.setState({ inputType: "tel" });
        }
    };
    handleBlur(event: any) {
        const {
            application,
            merchant: { merchantName },
            payment,
            calculateFee,
            billTotal,
        } = this.props;
        if (checkLuhn(payment.cardNumber)) {
            if (calculateFee) {
                calculateFee
                (
                    application,
                    merchantName,
                    "en",
                    billTotal,
                    null,
                    1,
                    payment.cardNumber
                );
            }
            if (
                application.isBillDetail &&
                this.props.merchant.allowAutoBillPay
            ) {
                this.props.loadAutoPayTerms!(
                    application,
                    merchantName,
                    484,
                    7,
                    1
                );
            }
        }
        if (event.target.name === "cvc") {
            this.setState({ inputType: "password" });
        }
        if (this.props.onBlur) {
            this.props.onBlur(event);
        }
    }

    handleChange(event: any) {
        const { checkExpired, onError, payment } = this.props;
        const field = event.target.name;
        const checkExpiredField =
            checkExpired &&
            (field === "expirationMonth" || field === "expirationYear");
        if (checkExpiredField) {
            if (
                field === "expirationMonth" &&
                isCardExpired(event.target.value, payment.expirationYear)
            ) {
                this.monthInput.setCustomValidity("form.error.expiredCard");
            } else if (
                field === "expirationYear" &&
                isCardExpired(payment.expirationMonth, event.target.value)
            ) {
                this.monthInput.setCustomValidity("form.error.expiredCard");
            } else {
                this.monthInput.setCustomValidity("");
            }
        }
        if (checkExpiredField) {
            CheckFieldValidity(this.monthInput, onError);
        } else if (event.target.validity.valid) {
            CheckFieldValidity(event.target, onError);
        }
        if (this.props.onChange) {
            this.props.onChange(event);
        }
    }


    //TO-DO : Colby, get PR through on evbmodel, update nuget package in api, verify that this returns "Dev"~ish on  my local, make sure this will return "Production" on prod web api servers
    private getAppEnvironment = () => {
        const { merchant } = this.props;
        if (merchant.environment === 'Production') {
            return 'PRODUCTION';
        } else {
            return 'TEST';
        }
    }

    callCardTypeWrapper = (
        cardNumber: string,
        setShowCardType: (cardType: string) => void
    ) => {
        const {
            application,
            merchant: { merchantName },
        } = this.props;
        this.props.callCardType(
            application,
            merchantName,
            "en",
            1,
            cardNumber,
            setShowCardType
        );
    };

    handleApplePayButtonClick = () => {
        const {
            merchant,
            payment,
            allowedCardNetworks,
            billTotal,
            getApplePayBillingContact,
            applePayRequiredFields
        } = this.props;

        const applePayRequest: ApplePayJS.ApplePayPaymentRequest = {
            countryCode: 'US',
            currencyCode: 'USD',
            supportedNetworks: allowedCardNetworks,
            merchantCapabilities: ['supports3DS', 'supportsCredit', 'supportsDebit'],
            total: {
                label: merchant.friendlyName,
                amount: (payment as IPayment).total?.toFixed(2) ?? "0.00"
            },
            lineItems: [
                {
                    label: merchant.convenienceFeeLabel,
                    amount: (payment as IPayment).convenienceFee?.toFixed(2) ?? "0.00"
                },
                {
                    label: "SubTotal",
                    amount: billTotal.toFixed(2)
                }
            ],
            requiredBillingContactFields: applePayRequiredFields,
            billingContact: getApplePayBillingContact
        };

        console.log(applePayRequest);

        // Create ApplePaySession
        const session = new ApplePaySession(3, applePayRequest);

        session.onvalidatemerchant = async () => {
            try {
                console.log("Starting merchant validation");
                const merchantSession = await applePayValidateMerchant();
                session.completeMerchantValidation(merchantSession);
                console.log("Validated Merchant Successfully")
            } catch (error) {
                session.abort();
            }
        };

        session.onpaymentauthorized = (event) => {
            try {
                const paymentData = event.payment;
                const address = paymentData.billingContact?.addressLines;

                payment.firstName = paymentData.billingContact?.givenName;
                payment.lastName = paymentData.billingContact?.familyName;
                payment.address = (address && address.length > 0 ? address.join(" ") : "");
                payment.cardNumber = "xxxx xxxx xxxx " + paymentData.token.paymentMethod.displayName.slice(-4);
                payment.city = paymentData.billingContact?.locality;
                payment.country = paymentData.billingContact?.countryCode;
                payment.mobilePhone = paymentData.billingContact?.phoneNumber;
                payment.phone = paymentData.billingContact?.phoneNumber;
                payment.postal = paymentData.billingContact?.postalCode;
                payment.state = paymentData.billingContact?.administrativeArea;
                payment.type = paymentData.token.paymentMethod.network;
                payment.applePayToken = JSON.stringify(paymentData.token.paymentData);

                if (paymentData) {
                    session.completePayment(ApplePaySession.STATUS_SUCCESS);
                    this.props.handleWalletPaymentSubmitted();

                } else {
                    session.completePayment(ApplePaySession.STATUS_FAILURE);
                    console.error('Payment processing failed');
                }

            } catch (error) {
                console.error('Error during payment authorization', error);
                session.completePayment(ApplePaySession.STATUS_FAILURE);
            }
        };

        try {
            session.begin();
        } catch (error) {
            console.error('Error starting Apple Pay session', error);
        }
    
    };

    render() {
        const {
            merchant,
            payment,
            hideFeePanel,
            hideFutureDated,
            useGooglePay, 
            showGooglePay,
            useApplePay,
            showApplePay,
            allowedCardNetworks,
            errors,
            onChecked,
            billTotal,
        } = this.props;
        const {
            showRememberAccount,
            showAccountNickName,
            hideCvcPanel,
            readOnlyField,
        } = this.props;
        const props: any = Object.assign({}, this.props);
        Object.keys(this.props).forEach((key) => {
            if (key in CreditCardPanelPropsEnum) {
                delete props[key];
            }
        });
        const isAMEX = IsAmericanExpress(payment.cardNumber);
        const popoverTitle = isAMEX
            ? Resources.TooltipHeaderAmex
            : Resources.TooltipHeaderOther;
        const popover = (
            <Popover id="popover" title={popoverTitle}>
                {isAMEX ? (
                    <Image src={cvv4} responsive />
                ) : (
                    <Image src={cvv3} responsive />
                )}
            </Popover>
        );
        const feedback = (
            <InputGroup.Addon>
                <OverlayTrigger
                    trigger={["hover", "focus"]}
                    placement="bottom"
                    overlay={popover}
                >
                    <FontAwesomeIcon icon={faQuestionCircle} />
                </OverlayTrigger>
            </InputGroup.Addon>
        );
        const isRequired = payment.method === "credit";
        const monthRef = (select: any) => {
            this.monthInput = select;
        };
        const appEnvironment = this.getAppEnvironment();
        const showWalletOptions = ((useGooglePay && showGooglePay) || (useApplePay && showApplePay));
        return (
            <Panel>
                <Panel.Body>
                    <Row>
                        <Col xs={12} sm={12} md={12}>
                            <CreditCardNumberInput
                                {...props}
                                label={Resources.LabelCardNumber}
                                name="cardNumber"
                                value={payment.cardNumber}
                                enableVisa={merchant.visaIsAccepted}
                                enableMaster={merchant.masterCardIsAccepted}
                                enableDiscover={merchant.discoverIsAccepted}
                                enableAmex={merchant.americanExpressIsAccepted}
                                enableGoogle={useGooglePay }
                                enableApple={useApplePay }
                                paymentMethod={payment.paymentMethod}
                                error={errors.cardNumber}
                                onBlur={this.handleBlur}
                                onChange={this.props.onChange}
                                onError={this.props.onError}
                                readOnly={readOnlyField}
                                required={isRequired}
                                callCardType={this.callCardTypeWrapper}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} sm={12} md={12}>
                            <TextInput
                                label={Resources.LabelNameOnCard}
                                name="nameOnAccount"
                                value={payment.nameOnAccount}
                                error={errors.nameOnAccount}
                                onBlur={this.props.onBlur}
                                onChange={this.props.onChange}
                                onError={this.props.onError}
                                readOnly={readOnlyField}
                                required={isRequired}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col
                            {...(hideCvcPanel
                                ? { md: 7, sm: 7, xs: 7 }
                                : { md: 5, sm: 7, xs: 7 })}
                        >
                            <MonthSelectInput
                                label={Resources.LabelMonth}
                                name="expirationMonth"
                                value={payment.expirationMonth}
                                error={errors.expirationMonth}
                                onBlur={this.props.onBlur}
                                onChange={this.handleChange}
                                onError={this.props.onError}
                                required={isRequired}
                                inputRef={monthRef}
                            />
                        </Col>
                        <Col
                            {...(hideCvcPanel
                                ? { md: 5, sm: 5, xs: 5 }
                                : { md: 3, sm: 5, xs: 5 })}
                        >
                            <YearSelectInput
                                label={Resources.LabelYear}
                                name="expirationYear"
                                value={payment.expirationYear}
                                error={errors.expirationYear}
                                onBlur={this.props.onBlur}
                                onChange={this.handleChange}
                                onError={this.props.onError}
                                required={isRequired}
                            />
                        </Col>
                        {!hideCvcPanel && (
                            <Col xs={12} sm={12} md={4}>
                                <NumberInput
                                    label={Resources.LabelCode}
                                    name="cvc"
                                    value={payment.cvc}
                                    type={this.state.inputType}
                                    minLength={isAMEX ? 4 : 3}
                                    maxLength={isAMEX ? 4 : 3}
                                    // format={isAMEX ? "####" : "###"}
                                    error={errors.cvc}
                                    onBlur={this.handleBlur}
                                    onFocus={this.handleFocus}
                                    onChange={this.props.onChange}
                                    onError={this.props.onError}
                                    placeholder="CVC"
                                    required={isRequired}
                                    addonAfter={feedback}
                                />
                            </Col>
                        )}
                    </Row>
                    {showRememberAccount && (
                        <Row>
                            <Col xs={12} sm={12} md={12}>
                                <Checkbox
                                    checked={
                                        payment.saveAccountInMyAccountWallet
                                    }
                                    name="saveAccountInMyAccountWallet"
                                    onChange={onChecked}
                                >
                                    {Resources.MessageRememberCard}
                                </Checkbox>
                            </Col>
                        </Row>
                    )}
                    {!hideFutureDated && (
                        <Row>
                            <Col xs={7} sm={7} md={5}>
                                <FutureDatePayment
                                    name="futurePaymentDate"
                                    locale={this.props.locale}
                                    label={merchant.futureDatedPaymentLabel}
                                    selectedDate={payment.futurePaymentDate}
                                    maxNumberOfDays={
                                        this.props.maxFuturePaymentDays
                                    }
                                    onChanged={this.props.onChange}
                                />
                            </Col>
                        </Row>
                    )}
                    {/*ShowWalletOptions*/}
                    {showWalletOptions && (<Row>
                        {/*GooglePayButtonHere*/}
                        {(useGooglePay && showGooglePay )&& (
                            <Col style={{ textAlign: 'center' }} xs={12} sm={12} md={(useApplePay && showApplePay) ? 6: 12}>
                            <GooglePayButton
                                environment={appEnvironment}
                                buttonColor="black"
                                buttonType="pay"
                                paymentRequest={{
                                    apiVersion: 2,
                                    apiVersionMinor: 0,
                                    allowedPaymentMethods: [
                                        {
                                            type: "CARD",
                                            parameters: {
                                                allowedAuthMethods: ["PAN_ONLY", "CRYPTOGRAM_3DS"],
                                                allowedCardNetworks: allowedCardNetworks,
                                                billingAddressRequired: true,
                                                billingAddressParameters: {
                                                    format: "FULL",
                                                },
                                            },
                                            tokenizationSpecification: {
                                                type: "PAYMENT_GATEWAY",
                                                parameters: {
                                                    gateway: "globalpayments", 
                                                    gatewayMerchantId: merchant.heartlandMID,  
                                                },
                                            },
                                        },
                                    ],
                                    callbackIntents: ["PAYMENT_AUTHORIZATION"],
                                    merchantInfo: {
                                        merchantId: merchant.googlePayMerchantID, 
                                        merchantName: merchant.friendlyName, 
                                    },
                                    transactionInfo: {
                                        displayItems: [
                                            {
                                                label: "Amount",
                                                type: "SUBTOTAL",
                                                price: billTotal.toFixed(2),
                                            },
                                            {
                                                label: merchant.convenienceFeeLabel,
                                                type: "LINE_ITEM",
                                                price: (payment as IPayment).convenienceFee?.toFixed(2) ?? "0.00",
                                            },
                                        ],
                                        totalPriceStatus: "FINAL",
                                        totalPriceLabel: "Total",
                                        totalPrice: (payment as IPayment).total?.toFixed(2) ?? "0.00",
                                        currencyCode: "USD",
                                        countryCode: "US",
                                    },
                                }}
                                onLoadPaymentData={(paymentRequest) => {
                                    this.props.handleWalletPaymentSubmitted();
                                }}
                                    onPaymentAuthorized={async (paymentData) =>  
                                        await this.handlePayment(paymentData)
                                        //return { transactionState: 'SUCCESS' };
                                }
                                
                            />
                            </Col>
                        )}
                        {(useApplePay && showApplePay) && (
                            <Col style={{ textAlign: 'center' }} xs={12} sm={12} md={(useGooglePay && showGooglePay) ? 6 : 12}>
                                    <ApplePayButton
                                    onClick={async () => await this.handleApplePayButtonClick()}
                                    buttonStyle='black'
                                    type='pay'
                                    style={{ boxSizing: 'content-box', width: '240px' }}
                                    />
                                </Col>
                        )}
                    </Row>)}
                    {!hideFeePanel && (
                        <Row>
                            <Col xs={12} sm={12} md={12}>
                                <br />
                                <FeeTable
                                    merchant={merchant}
                                    payment={payment}
                                    billTotal={billTotal}
                                />
                            </Col>
                        </Row> 
                    )}
                    <Row>
                        <Col xs={12} sm={12} md={12}>
                            {errors.system && (
                                <Alert bsStyle="danger">
                                    <FontAwesomeIcon
                                        icon={faExclamationTriangle}
                                    />{" "}
                                    {errors.system}
                                </Alert>
                            )}
                        </Col>
                    </Row>
                </Panel.Body>
            </Panel>
        );
    }
}

export default CreditCardPanel;
