import React from "react";
import {
    Alert,
    Button,
    Col,
    Nav,
    NavItem,
    Panel,
    Row,
    Tab,
} from "react-bootstrap";
import { InjectedIntlProps, injectIntl } from "react-intl";
import {
    activateFactor,
    challenge,
    enrollCallFactor,
    enrollSmsFactor,
    enrollTotpOktaFactor,
    unenrollFactor,
    verifyFactor,
    getCustomerProfile,
} from "../../../api/User";
import {
    mapFactorTypeToEnrollType,
    canFactorResend,
} from "../../../utils/Identity";
import { intlErrorTypeGruard } from "../../../core/exceptions";
import * as Resources from "../../../resources";
import { MessageMultiFactorEnrollmentInstructions } from "../../../resources";
import { EnrollTypes, IApplication } from "../../../types";
import { IFormError } from "../../../types/IFormError";
import { FactorType, IUser, IFactor, IFactorTotp } from "../../../types/IUser";
import { BlockUi } from "../BlockUi";
import ForgotPasswordPanel from "./ForgotPasswordPanel";
import MultiFactorCodeEntryPanel from "./MultiFactorCodeEntryPanel";
import MultiFactorEnrollCallPanel from "./MultiFactorEnrollCallPanel";
import MultiFactorEnrollSmsPanel from "./MultiFactorEnrollSmsPanel";
import MultiFactorManagementPanel from "./MultiFactorManagementPanel";
import MultiFactorPanel from "./MultiFactorPanel";
import { default as RegisterPanel } from "./RegisterPanel";
import SignInPanel from "./SignInPanel";

export interface LoginFormProps {
    application: IApplication;
    merchantName: string;
    allowAutoPay: boolean;
    requireRecaptcha: boolean;
    requireLoginEBPP: boolean;
    requirePasswordReset: boolean;
    locale: string;
    allowGuestLogin: boolean;
    webPaymentsCustomerAccountLoginMessage?: string;
    user: IUser;
    onClose?: () => void;
    onContinue?: () => void;
    updateUser: (user: IUser) => void;
    userVerified: (value: boolean) => void;
    login: (
        userName: string,
        password: string,
        recaptcha?: string | null
    ) => Promise<IUser>;
    logout: () => void;
    loadUserAccounts: (
        application: IApplication,
        merchantName: string,
        authToken: string,
        onError?: (text: string) => void
    ) => void;
    loadCustomerBillDetails: (
        merchantName: string,
        authToken: string,
        merchantCustomerId: string
    ) => void;
}

export interface LoginFormState {
    accountCreated: boolean;
    error: string | JSX.Element | null;
    errors: IFormError;
    factorId: string;
    key:
        | "activateMfa"
        | "enrollMfa"
        | EnrollTypes
        | "forgotPassword"
        | "mfa"
        | "register"
        | "registerFinish"
        | "signin"
        | "verifyMfa";
    isRecaptchaLoaded: boolean;
    isSubmitting: boolean;
    email: string;
    password: string;
    authToken: string;
    recaptcha?: string | null;
}

class LoginForm extends React.Component<
    LoginFormProps & InjectedIntlProps,
    LoginFormState
> {
    constructor(props: LoginFormProps & InjectedIntlProps) {
        super(props);

        const initialKey = this.props.requirePasswordReset
            ? "registerFinish"
            : "signin";
        this.state = {
            accountCreated: false,
            error: null,
            errors: {},
            factorId: "",
            key: initialKey,
            isRecaptchaLoaded: false,
            isSubmitting: false,
            email: "",
            password: "",
            recaptcha: undefined,
            authToken: "",
        };

        this.onContinueAsGuest = this.onContinueAsGuest.bind(this);
        this.onClose = this.onClose.bind(this);
        this.onSelect = this.onSelect.bind(this);
        this.onForgotPassword = this.onForgotPassword.bind(this);
        this.onRecaptchaLoaded = this.onRecaptchaLoaded.bind(this);
        this.onChallengeMfa = this.onChallengeMfa.bind(this);
        this.onCancelMfa = this.onCancelMfa.bind(this);
        this.onEnrollMfa = this.onEnrollMfa.bind(this);
        this.handleManageFactor = this.handleManageFactor.bind(this);
        this.handleEnrollSms = this.handleEnrollSms.bind(this);
        this.handleEnrollCall = this.handleEnrollCall.bind(this);
        this.handleActivate = this.handleActivate.bind(this);
        this.handleVerify = this.handleVerify.bind(this);
        this.handleChallenge = this.handleChallenge.bind(this);
        this.loadMyAccountInformation =
            this.loadMyAccountInformation.bind(this);
        this.onPasswordSet = this.onPasswordSet.bind(this);
    }

    public componentDidUpdate(prevProps: any): void {
        if (
            this.props.application.isEBPP &&
            this.props.requirePasswordReset &&
            this.state.key === "signin"
        ) {
            this.onRecaptchaLoaded();
            this.onSelect("registerFinish");
        }
    }

    private onClose(event: any): void {
        this.onSelect("signin");

        if (this.props.onClose) {
            this.props.onClose();
        }
    }

    private onRecaptchaLoaded(): void {
        this.setState({ isRecaptchaLoaded: true });
    }

    private onContinueAsGuest(event: React.MouseEvent<Button>): void {
        this.props.onContinue && this.props.onContinue();
    }

    private onForgotPassword(): void {
        this.onSelect("forgotPassword");
    }

    private onSelect(key: any): void {
        this.setState({ key });
    }

    private onPasswordSet(email: string, password: string, recaptcha?: string | null, authToken?: string): void {
        this.setState({ email, password, recaptcha, authToken: (authToken || this.props.user.authToken) });
    }

    private loadMyAccountInformation(authToken?: string): void {
        if (this.props.application.id !== 35) {
            if (this.props.loadUserAccounts) {
                this.props.loadUserAccounts(
                    this.props.application,
                    this.props.merchantName,
                    authToken || this.props.user.authToken
                );
            }

            getCustomerProfile(
                this.props.application,
                this.props.merchantName,
                this.state.email,
                this.state.password,
                authToken || this.props.user.authToken,
                this.state.recaptcha
            ).then((result) => {
                if (result) {
                    if (this.props.updateUser) {
                        this.props.updateUser({
                            ...this.props.user,
                            profile: result
                        });
                    }
                }
            });

            if (this.props.allowAutoPay) {
                if (this.props.loadCustomerBillDetails) {
                    this.props.loadCustomerBillDetails(
                        this.props.merchantName,
                        authToken || this.props.user.authToken,
                        this.props.user.merchantCustomerID
                    );
                }
            }
        }
    }

    private onChallengeMfa(): void {
        if (
            this.props.user.factors.filter(
                (f) => f.factorInfo.status === "ACTIVE"
            ).length === 0
        ) {
            this.setState({ key: "enrollMfa" });
        } else {
            this.setState({ key: "mfa" });
        }
    }

    private onEnrollMfa(): void {
        this.setState({ key: "enrollMfa", accountCreated: true });
    }

    private onCancelMfa(): void {
        if (this.props.onClose) {
            if (this.props.user.factors.length === 0 && this.props.logout) {
                this.props.logout();
            }

            this.props.onClose();
        }
    }

    private handleManageFactor(factorType: FactorType, enabling: boolean) {
        //call API to enable or disable a factor, if enabling we need to navigate user to screen to enter factor.
        if (enabling) {
            switch (factorType) {
                case "token:software:totp":
                    this.handleEnrollTotpOkta();
                    break;
                default: {
                    this.setState({
                        key: mapFactorTypeToEnrollType(factorType),
                    });
                }
            }
        } else {
            //make api call to unenroll from factor.
            const factor = this.props.user.factors.find(
                (f) => f.factorInfo.factorType === factorType
            );

            if (factor) {
                this.setState({ isSubmitting: true, error: null });
                unenrollFactor(this.props.user.userId, factor.factorId)
                    .then((result) => {
                        this.setState({ isSubmitting: false });
                        if (result.isSuccessful) {
                            const factors = this.props.user.factors.filter(
                                (f) => f.factorInfo.factorType !== factorType
                            );

                            if (this.props.updateUser) {
                                this.props.updateUser({
                                    ...this.props.user,
                                    isVerified: factors.length > 0,
                                    factors: this.props.user.factors.filter(
                                        (f) =>
                                            f.factorInfo.factorType !==
                                            factorType
                                    ),
                                });
                            }
                        }
                    })
                    .catch((err) => {
                        this.setState({
                            error: err.message,
                            isSubmitting: false,
                        });
                    });
            }
        }
    }

    private handleEnrollSms(phoneNumber: string) {
        this.setState({ isSubmitting: true, error: null });
        enrollSmsFactor(
            this.props.user.userId,
            phoneNumber,
            this.props.user.authToken
        )
            .then((result) => {
                this.setState({ isSubmitting: false });
                if (result.isSuccessful) {
                    if (result.factor.factorInfo.status === "ACTIVE") {
                        if (this.props.updateUser) {
                            this.props.updateUser({
                                ...this.props.user,
                                isVerified: true,
                                authToken: result.authToken,
                                factors: [
                                    ...this.props.user.factors,
                                    result.factor,
                                ],
                            });
                            this.loadMyAccountInformation(result.authToken);
                        }
                        this.setState({ key: "enrollMfa" });
                    } else {
                        if (this.props.updateUser) {
                            this.props.updateUser({
                                ...this.props.user,
                                factors: [
                                    ...this.props.user.factors,
                                    result.factor,
                                ],
                            });
                        }
                        this.setState({
                            factorId: result.factor.factorId,
                            key: "activateMfa",
                        });
                    }
                }
            })
            .catch((err) => {
                this.setState({ error: err.message, isSubmitting: false });
            });
    }
    private handleEnrollTotpOkta = () => {
        this.setState({ isSubmitting: true, error: null });
        enrollTotpOktaFactor(this.props.user.userId)
            .then((result) => {
                this.setState({ isSubmitting: false });
                if (result.isSuccessful) {
                    if (result.factor.factorInfo.status === "ACTIVE") {
                        if (this.props.updateUser) {
                            this.props.updateUser({
                                ...this.props.user,
                                isVerified: true,
                                authToken: result.authToken,
                                factors: [
                                    ...this.props.user.factors,
                                    result.factor,
                                ],
                            });

                            this.loadMyAccountInformation(result.authToken);
                        }
                        this.setState({ key: "enrollMfa" });
                    } else {
                        if (this.props.updateUser) {
                            const existingFactor = this.props.user.factors.find(
                                (n) => n.factorId === result.factor.factorId
                            );
                            this.props.updateUser({
                                ...this.props.user,
                                factors: existingFactor
                                    ? [
                                          ...this.props.user.factors.filter(
                                              (n) =>
                                                  n.factorId !=
                                                  result.factor.factorId
                                          ),
                                          result.factor,
                                      ]
                                    : [
                                          ...this.props.user.factors,
                                          result.factor,
                                      ],
                            });
                        }
                        this.setState({
                            factorId: result.factor.factorId,
                            key: "activateMfa",
                        });
                    }
                }
            })
            .catch((err) => {
                this.setState({ error: err.message, isSubmitting: false });
            });
    };

    private handleEnrollCall(
        phoneNumber: string,
        extension: string,
        regionCode: string
    ) {
        this.setState({ isSubmitting: true, error: null });
        enrollCallFactor(
            this.props.user.userId,
            phoneNumber,
            extension,
            regionCode
        )
            .then((result) => {
                this.setState({ isSubmitting: false });
                if (result.isSuccessful) {
                    if (result.factor.factorInfo.status === "ACTIVE") {
                        if (this.props.updateUser) {
                            this.props.updateUser({
                                ...this.props.user,
                                isVerified: true,
                                authToken: result.authToken,
                                factors: [
                                    ...this.props.user.factors,
                                    result.factor,
                                ],
                            });
                            this.loadMyAccountInformation(result.authToken);
                        }
                        this.setState({ key: "enrollMfa" });
                    } else {
                        if (this.props.updateUser) {
                            this.props.updateUser({
                                ...this.props.user,
                                factors: [
                                    ...this.props.user.factors,
                                    result.factor,
                                ],
                            });
                        }
                        this.setState({
                            factorId: result.factor.factorId,
                            key: "activateMfa",
                        });
                    }
                }
            })
            .catch((err) => {
                this.setState({ error: err.message, isSubmitting: false });
            });
    }

    private handleActivate(
        factorId: string,
        code: string,
        rememberDevice: boolean
    ) {
        this.setState({ isSubmitting: true });
        activateFactor(
            this.props.application,
            this.props.user.authToken,
            this.props.merchantName,
            factorId,
            this.props.user.userId,
            code,
            rememberDevice
        )
            .then((result) => {
                this.setState({ isSubmitting: false });
                if (result.isSuccessful) {
                    if (this.props.updateUser) {
                        this.props.updateUser({
                            ...this.props.user,
                            factors: this.props.user.factors.map((f) => {
                                if (f.factorId === factorId) {
                                    return result.factor;
                                }

                                return f;
                            }),
                            isVerified: true,
                            authToken: result.authToken,
                        });
                        this.loadMyAccountInformation(result.authToken);
                    }

                    this.setState({ key: "enrollMfa" });
                }
            })
            .catch((err) => {
                this.setState({
                    error: err.message,
                });
            });
    }

    private handleChallenge(factorId: string) {
        this.setState({ isSubmitting: true, error: null });
        challenge(factorId, this.props.user.userId, this.props.locale)
            .then((result) => {
                this.setState({ isSubmitting: false });
                if (result.isSuccessful) {
                    this.setState({ factorId, key: "verifyMfa" });
                }
            })
            .catch((err) => {
                this.setState({ error: err.message, isSubmitting: false });
            });
    }

    private handleVerify(
        factorId: string,
        code: string,
        rememberDevice: boolean,
        recaptcha?: string | null
    ) {
        this.setState({ isSubmitting: true, error: null });
        verifyFactor(
            this.props.application,
            factorId,
            this.props.merchantName,
            this.props.user.userId,
            code,
            rememberDevice,
            this.props.locale,
            this.props.user.authToken,
            recaptcha
        )
            .then((result) => {
                this.setState({ isSubmitting: false });
                if (result.isSuccessful) {
                    if (this.props.updateUser) {
                        this.props.updateUser({
                            ...this.props.user,
                            isVerified: true,
                            authToken: result.authToken,
                        });
                        this.loadMyAccountInformation(result.authToken);
                    }
                } else if (result.signOutRequired) {
                    this.props.logout();
                }
                if (this.props.onClose) {
                    this.props.onClose();
                }
            })
            .catch((err) => {
                const error = intlErrorTypeGruard(err)
                    ? this.props.intl.formatMessage({
                          id: err.messageId,
                          defaultMessage: err.message,
                      })
                    : err.message;

                this.setState({
                    error,
                    isSubmitting: false,
                });
            });
    }

    public render() {
        const { user } = this.props;
        const { factorId } = this.state;
        let currentFactor = null;

        if (user.factors) {
            currentFactor = user.factors.find(
                (n) => n && n.factorId === factorId
            );
        }

        const canResend =
            currentFactor != null
                ? canFactorResend(currentFactor.factorInfo.factorType)
                : true;
        return (
            <div>
                <BlockUi shouldRender={this.state.isSubmitting} />
                {!!this.props.webPaymentsCustomerAccountLoginMessage && (
                    <Alert bsStyle="warning">
                        {this.props.webPaymentsCustomerAccountLoginMessage}
                    </Alert>
                )}
                {!!this.state.error && (
                    <Alert bsStyle="warning">{this.state.error}</Alert>
                )}
                {(this.state.key === "registerFinish" ||
                    this.state.key === "signin" ||
                    this.state.key === "register") && (
                    <Tab.Container
                        id="tabs-with-dropdown"
                        activeKey={this.state.key}
                        onSelect={this.onSelect}
                    >
                        <Row className="clearfix">
                            <Col xs={12}>
                                <Nav bsStyle="tabs" justified>
                                    {!this.props.requirePasswordReset && (
                                        <NavItem eventKey="signin">
                                            <h4>{Resources.SignIn}</h4>
                                        </NavItem>
                                    )}
                                    {!(
                                        this.props.requireLoginEBPP ||
                                        this.props.requirePasswordReset
                                    ) && (
                                        <NavItem eventKey="register">
                                            <h4>{Resources.Register}</h4>
                                        </NavItem>
                                    )}
                                    {this.props.requirePasswordReset && (
                                        <NavItem eventKey="registerFinish">
                                            <h4>{Resources.RegisterFinish}</h4>
                                        </NavItem>
                                    )}
                                </Nav>
                            </Col>
                            <Col xs={12}>
                                <Tab.Content animation unmountOnExit>
                                    {!this.props.requirePasswordReset && (
                                        <Tab.Pane eventKey="signin">
                                            <SignInPanel
                                                application={
                                                    this.props.application
                                                }
                                                merchantName={
                                                    this.props.merchantName
                                                }
                                                allowAutoPay={
                                                    this.props.allowAutoPay
                                                }
                                                requireRecaptcha={
                                                    this.props.requireRecaptcha
                                                }
                                                isRecaptchaLoaded={
                                                    this.state.isRecaptchaLoaded
                                                }
                                                onClose={this.props.onClose}
                                                onChallengeMfa={
                                                    this.onChallengeMfa
                                                }
                                                onContinue={
                                                    this.props.onContinue
                                                }
                                                updateUser={
                                                    this.props.updateUser
                                                }
                                                login={this.props.login}
                                                loadUserAccounts={
                                                    this.props.loadUserAccounts
                                                }
                                                loadCustomerBillDetails={
                                                    this.props
                                                        .loadCustomerBillDetails
                                                }
                                                onForgotPassword={
                                                    this.onForgotPassword
                                                }
                                                onRecaptchaLoaded={
                                                    this.onRecaptchaLoaded
                                                }
                                                onPasswordSet={
                                                    this.onPasswordSet
                                                }
                                            />
                                        </Tab.Pane>
                                    )}
                                    {!(
                                        this.props.requireLoginEBPP ||
                                        this.props.requirePasswordReset
                                    ) && (
                                        <Tab.Pane eventKey="register">
                                            <RegisterPanel
                                                {...this.props}
                                                onChallengeMfa={
                                                    this.onEnrollMfa
                                                }
                                            />
                                        </Tab.Pane>
                                    )}
                                    {this.props.requirePasswordReset && (
                                        <Tab.Pane eventKey="registerFinish">
                                            <ForgotPasswordPanel
                                                application={
                                                    this.props.application
                                                }
                                                merchantName={
                                                    this.props.merchantName
                                                }
                                                requirePasswordReset={
                                                    this.props
                                                        .requirePasswordReset
                                                }
                                                requireRecaptcha={
                                                    this.props.requireRecaptcha
                                                }
                                                onRecaptchaLoaded={
                                                    this.onRecaptchaLoaded
                                                }
                                                handleOnClose={
                                                    this.props.onClose
                                                }
                                                updateUser={
                                                    this.props.updateUser
                                                }
                                            />
                                        </Tab.Pane>
                                    )}
                                </Tab.Content>
                            </Col>
                        </Row>
                    </Tab.Container>
                )}
                {this.state.key === "forgotPassword" && (
                    <ForgotPasswordPanel
                        {...this.props}
                        handleOnClose={this.props.onClose}
                        onRecaptchaLoaded={this.onRecaptchaLoaded}
                    />
                )}
                {this.state.key === "mfa" && (
                    <MultiFactorPanel
                        onClose={this.onCancelMfa}
                        onVerifyFactor={this.handleChallenge}
                        factors={this.props.user.factors}
                    />
                )}
                {this.state.key === "activateMfa" && (
                    <MultiFactorCodeEntryPanel
                        canRemember={true}
                        factorId={this.state.factorId}
                        onClose={this.onCancelMfa}
                        onOk={this.handleActivate}
                        onResend={this.handleChallenge}
                        canFactorResend={canResend}
                        totpInfo={currentFactor as IFactorTotp}
                    />
                )}

                {this.state.key === "verifyMfa" && (
                    <MultiFactorCodeEntryPanel
                        canRemember={true}
                        factorId={this.state.factorId}
                        onClose={this.onCancelMfa}
                        onOk={this.handleVerify}
                        onResend={this.handleChallenge}
                        canFactorResend={canResend}
                    />
                )}
                {this.state.key === "enrollMfa" && (
                    <MultiFactorManagementPanel
                        onClose={this.onCancelMfa}
                        onManageFactor={this.handleManageFactor}
                        header={
                            this.state.accountCreated
                                ? Resources.MessageMultiFactorEnrollmentInstructionsNewUser
                                : MessageMultiFactorEnrollmentInstructions
                        }
                        factors={this.props.user.factors}
                    />
                )}
                {this.state.key === "enrollCall" && (
                    <MultiFactorEnrollCallPanel
                        onClose={this.onChallengeMfa}
                        onOk={this.handleEnrollCall}
                    />
                )}
                {this.state.key === "enrollSms" && (
                    <MultiFactorEnrollSmsPanel
                        onClose={this.onChallengeMfa}
                        onOk={this.handleEnrollSms}
                    />
                )}
                {this.props.allowGuestLogin && !this.props.requireLoginEBPP && (
                    <Panel>
                        <Panel.Body>
                            <Button
                                bsStyle="primary"
                                disabled={!this.state.isRecaptchaLoaded}
                                onClick={this.onContinueAsGuest}
                                block
                            >
                                {Resources.ContinueAsGuest}
                            </Button>
                        </Panel.Body>
                    </Panel>
                )}
            </div>
        );
    }
}

export default injectIntl(LoginForm);
